
export const PROXY = {
	56: "", //bsc mainnet

	97: '0x4aC6CBD54F851dCFf0d5BF6d14B0fD05F74a35b9', // new bsc testnet

	137: "", //polygon mainnet

	1: "",

	3: "",

	43113: "", // avalanche testnet 

	42161: ""  //arbitrum
};

export const XLAUNCH = {
	56: "", //bsc mainnet

	97: '0xFfD1264bA54889dd8db6E9772f5De512cbF8bA93',//'0x03A8243291e5EaF4be46580Af3e7b625fC96300A',//'0xb4Eb2e12c13cC4f76DBF196Dee38e0C6C2F8D789',

	137: "", //polygon mainnet

	1: "",

	3: "",

	43113: "", // avalanche testnet 

	42161: ""  //arbitrum
};


export default {
	xtoken: {

		//bsc
		56: '0xc5c26A06FF435859d32c0D07c4418d8877b1E1dB', //M
		97: '0x9BFA6001b4241e6920ffE18D7082FF5eA9456B00', //T

		//Eth
		1: '', //M
		11155111: '', //T

		//Avax
		43114: '0x296EFfbF5Cc1Dad8035302E54A6360C09896dc45', //M
		43113: '', //T

		//Arbitrum
		42161: '', //M
		421614: '', //T

		//zkSync
		324: '', //M
		280: '', //T

	}
}